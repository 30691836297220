// function getAddress(obj) {
window.getAddress = function(obj, address_id){

  var getData = {
    params: {
      zipcode: obj.value
    },
    // responseType: 'js'
  };
  // https://zipcloud.ibsnet.co.jp/api/search?#{params}
  axios.get("https://zipcloud.ibsnet.co.jp/api/search",getData)
    .then(response => {
      // appendList(response.data.forms);
      // console.log(response.data["results"][0])

    // @address1 = result["results"][0]["address1"]
    // @address2 = result["results"][0]["address2"]
    // @address3 = result["results"][0]["address3"]
      if (!address_id.value){
        address_id.value = response.data["results"][0]["address1"]+  response.data["results"][0]["address2"] +  response.data["results"][0]["address3"];
      }


    })
    .catch(error => {
      window.error(error.response.data);
    });

}

