window.bill_detail_change = function(idx){

  let count = document.getElementById("bill_details_attributes_"+ idx +"_count").value;

  let base_price = document.getElementById("bill_details_attributes_"+ idx +"_base_price").value;
  let discount = document.getElementById("bill_details_attributes_"+ idx +"_discount").value;

  const view_price_elem = document.getElementById("view_detail_price_"+ idx );
  const total_elem = document.getElementById("detail_total_"+ idx);
  const view_total_elem = document.getElementById("view_detail_total_"+ idx );

  let price = base_price - Number(discount);
  view_price_elem.innerText = price.toLocaleString() + "円";

  let total = price * count;
  total_elem.value = total;
  view_total_elem.innerText = total.toLocaleString() + "円";
  bill_change();

}



window.bill_change = function(){

  let totals = document.querySelectorAll('[id^=detail_total_]')
  let subtotal = 0;
  totals.forEach(function(elem){
    subtotal += Number(elem.value);
  });

  const view_subtotal_elem = document.getElementById("view_subtotal");
  view_subtotal_elem.innerText = subtotal.toLocaleString() + "円";
  let subtotal_elem = document.getElementById("bill_subtotal");
  subtotal_elem.value =  subtotal;

  let discount = document.getElementById("bill_discount").value;
  let tax_rate = document.getElementById("bill_tax_rate").value;
  //
  //
  let total = subtotal;
  let tax = Math.ceil((total * Number(tax_rate)) / 100);
  let total_include_tax = total + tax  - discount;

  // //  税別計
  let elem = document.getElementById("bill_total_value");
  elem.innerText = total.toLocaleString() + "円";
  //
  //
  // //消費税
  elem = document.getElementById("bill_tax_value");
  elem.innerText = tax.toLocaleString() + "円";
  //
  // //合計
  elem = document.getElementById("bill_total_include_tax_value");
  elem.innerText = total_include_tax.toLocaleString() + "円";

}