// ***** 以下を追加 *****
document.addEventListener("turbolinks:load", () => {
  flatpickr.localize(flatpickr.l10ns.ja)
  const config = {
    allowInput: true,
    disableMobile: false
  }
  flatpickr('.calendar_form1', config);
});
// ***** 以上を追加 *****
